<script>
export default {
    setup() {
        const auth = useAuthStore();
        const layout = useLayoutStore();
        return { auth, layout }
    },
    computed: {
        ...mapGetters(useAuthStore, ['hasSession']),
        ...mapGetters(useResponseStore, ['htmlError', 'errors']),
        year() {
            return new Date().getFullYear();
        }
    },
}
</script>

<template>
    <RouterView />
</template>

