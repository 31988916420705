<script>
export default {
    props: {
        type: String,
    },
    data() {
        return {
            navigations: [
                {
                    title: 'Company',
                    permission: 'module.company',
                    children: [
                        {
                            title: 'Company',
                            link: '/dashboard/companies',
                            icon: 'BuildingSkyscraperIcon',
                            permission: 'module.company',
                        },
                        {
                            title: 'Branch',
                            link: '/dashboard/companies/branches',
                            icon: 'MicroscopeIcon',
                            permission: 'module.company.branch',
                        },
                        {
                            title: 'Company Type',
                            link: '/dashboard/companies/company-types',
                            icon: 'SettingsIcon',
                            permission: 'module.company.type',
                        },
                        {
                            title: 'Division',
                            link: '/dashboard/companies/divisions',
                            icon: 'Category2Icon',
                            permission: 'module.company.division',
                            module: ['employee'],
                        },
                        {
                            title: 'Position',
                            link: '/dashboard/companies/positions',
                            icon: 'UserCircleIcon',
                            permission: 'module.company.position',
                            module: ['employee'],
                        },
                        {
                            title: 'Structure',
                            link: '/dashboard/companies/structures',
                            icon: 'Hierarchy2Icon',
                            permission: 'module.company.structure',
                            module: ['employee'],
                        },
                        {
                            title: 'Branch Type',
                            link: '/dashboard/companies/branch-types',
                            icon: 'SettingsIcon',
                            permission: 'module.company.branch.type',
                        },
                        //add link here ...
                    ],
                },
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <PartialsDashboardLink :navigations="navigations" :type="type" />
</template>
