<script>
export default {
    props: {
        type: String,
    },
    data() {
        return {
            navigations: [],    
            // navigations: [
            //     {
            //         title: 'Contact',
            //         permission: 'module.contact',
            //         children: [
            //             {
            //                 title: 'Contact',
            //                 link: '/dashboard/contacts',
            //                 icon: 'AddressBookIcon',
            //                 permission: 'module.contact',
            //             },

            //             {
            //                 title: 'Category',
            //                 link: '/dashboard/contacts/categories',
            //                 icon: 'AdjustmentsIcon',
            //                 permission: 'module.contact.category',
            //             },

            //             {
            //                 title: 'Tag',
            //                 link: '/dashboard/contacts/tags',
            //                 icon: 'TagIcon',
            //                 permission: 'module.contact.tag',
            //             },
            //             //add link here ...


            //         ],
            //     },
            //     //add more nav here ...
            // ]
        }
    }
}
</script>

<template>
    <PartialsDashboardLink :navigations="navigations" :type="type" />
</template>
