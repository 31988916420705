<script>
export default {
    setup() {
        const store = useModuleProficiencyOrderStore();
        const config = useConfigurationStore();
        const helper = useHelperStore();
        return { store, config, helper };
    },
    computed: {
        contact() {
            if (this.config.get('contact.whatsapp')?.length > 0)
                return this.config.get('contact.whatsapp');
            return [];
        }
    }
}
</script>
<template>
    <div class="uk-inline">
        <div class="whatsapp-button uk-button uk-button-small uk-button-primary uk-padding-small">
            <MessagePlusIcon size="36" />
        </div>
        <div class="uk-card uk-card-body uk-card-default uk-padding-small" uk-drop="mode: click; pos: top-right">
            <h3 class="uk-card-header uk-padding-small uk-padding-remove-horizontal">
                {{ $t('Contact Us') }}
            </h3>
            <img src="/images/watera.gif" alt="logo" />
            <template v-for="(item, index) in contact" :key="index">
                <a class="uk-width-1-1 uk-button uk-button-primary uk-padding-remove uk-margin-small-bottom uk-flex uk-flex-middle uk-flex-center"
                    target="_blank" href="https://api.whatsapp.com/send?phone=6281120003623&text=Halo%20tekMIRA%20!%20">
                    <div class="uk-grid uk-padding-small" uk-grid>
                        <div class="uk-width-auto">
                            <BrandWhatsappIcon />
                        </div>
                        <div class="uk-width-expand uk-text-left uk-padding-small uk-padding-remove-vertical">
                            <span>
                                {{ $t('Customer Service') }}
                                <template v-if="contact.length > 1">
                                    {{ index + 1 }}
                                </template>
                            </span>
                        </div>
                    </div>
                </a>
            </template>
        </div>
    </div>
</template>


<style scoped>
.whatsapp-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
    border-radius: 100px;
}

@media (max-width: 767px) {
    .whatsapp-button {
        bottom: 70px;
        right: 20px;
    }
}

.uk-button svg {
    margin-right: 0px !important;
}
</style>
