<script>
export default {
    props: {
        type: String,
    },
    data() {
        return {
            navigations: [
                {
                    title: 'Product',
                    permission: 'module.product',
                    children: [

                        {
                            title: 'Product',
                            link: '/dashboard/products',
                            icon: 'BoxIcon',
                            permission: 'module.product',
                        },

                        {
                            title: 'Category',
                            link: '/dashboard/products/categories',
                            icon: 'AdjustmentsIcon',
                            permission: 'module.product.category',
                        },

                        {
                            title: 'Tag',
                            link: '/dashboard/products/tags',
                            icon: 'TagIcon',
                            permission: 'module.product.tag',
                        },

                        {
                            title: 'Measurement',
                            link: '/dashboard/products/measurements',
                            icon: 'RulerMeasureIcon',
                            permission: 'module.product.measurement',
                        },

                        {
                            title: 'Sample',
                            link: '/dashboard/products/samples',
                            icon: 'TestPipeIcon',
                            permission: 'module.product.sample',
                        },
                        //add link here ...

                    ],
                },
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <PartialsDashboardLink :navigations="navigations" :type="type" />
</template>
