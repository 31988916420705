<script>
export default {
    props: {
        type: String,
    },
    data() {
        return {
            navigations: [
                {
                    title: 'Sales',
                    permission: 'module.sale',
                    children: [
                        {
                            title: 'Invoice',
                            link: '/dashboard/sales/invoices',
                            icon: 'FileInvoiceIcon',
                            permission: 'module.sale.invoice',
                        },
                        {
                            title: 'Payment',
                            link: '/dashboard/sales/payments',
                            icon: 'FileDollarIcon',
                            permission: 'module.sale.payment',
                        },
                        {
                            title: 'Term Payment',
                            link: '/dashboard/sales/payment-terms',
                            icon: 'CreditCardIcon',
                            permission: 'module.sale.payment',
                        },
                        {
                            title: 'Delivery',
                            link: '/dashboard/sales/deliveries',
                            icon: 'BoxSeamIcon',
                            permission: 'module.sale.delivery',
                        },
                        {
                            title: 'Vouchers',
                            link: '/dashboard/sales/vouchers',
                            icon: 'GiftCardIcon',
                            permission: 'module.sale.voucher',
                        },
                        {
                            title: 'Proficiency',
                            link: '/dashboard/sales/proficiency-settings',
                            icon: 'RotateIcon',
                            permission: 'module.sale.proficiency.setting',
                        },
                        //add link here ...
                    ],
                },
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <PartialsDashboardLink :navigations="navigations" :type="type" />
</template>
