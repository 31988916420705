<script>
export default {
    setup() {
        const module = useModuleStore();
        return { module }
    },
    props: {
        type: String,
    },
    data() {
        return {
            navigations: [
                {
                    title: 'Term',
                    permission: 'module.term',
                    children: [
                        {
                            title: 'Term',
                            link: '/dashboard/terms',
                            icon: 'AppsIcon',
                            permission: 'module.term',
                        },
                        //add link here ...
                    ],
                },
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <PartialsDashboardLink :navigations="navigations" :type="type" v-if="!module.check('accounting')" />
</template>
