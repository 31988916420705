import $axios from '~/helpers/axios';

const blankData = {
    cart: null,
    sampleAddress: {
        name: null,
        msisdn: null,
        type: null,
        latitude: null,
        longitude: null,
        street: null,
        place: null,
    },
    invoiceAddress: {
        name: null,
        msisdn: null,
        type: null,
        latitude: null,
        longitude: null,
        street: null,
        place: null,
    },
    paymentMethod: { id: 'direct-payment', name: 'Direct Payment' },
};

export const useModuleProficiencyOrderStore = defineStore('module.proficiency.order', {
    state: () => ({
        moduleName: 'Proficiency Orders',
        permission: 'module.proficiency.order',
        apiRoute: '/proficiencies/orders',
        route: '/dashboard/proficiencies/orders',
        products: null,
        data: null,
        list: null,
        create: false,
        edit: false,
        view: true,
        loading: true,
        form: blankData,
        options: {
            paymentMethods: [
                { id: 'direct-payment', name: 'Direct Payment' },
                { id: 'term-payment', name: 'Term Payment' },
            ],
            status: [
                { id: 'draft', name: 'Draft' },
                { id: 'open', name: 'Open' },
                { id: 'approved', name: 'Approved' },
                { id: 'done', name: 'Done' },
                { id: 'canceled', name: 'Canceled' },
            ],
            paymentStatus: [
                { id: 'unpaid', name: 'Unpaid' },
                { id: 'partial', name: 'Partial' },
                { id: 'paid', name: 'Paid' },
            ],
        },
        filter: {
            company: null,
            order: null,
            quotation: null,
            sales: null,
            contact: null,
            warehouse: null,
            refference: null,
            term: null,
            'createdAt.from': null,
            'createdAt.to': null
        },
    }),

    actions: {
        fetchProducts(payload) {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get('/proficiencies/queries/saleable-products', { params: payload }).then(res => {
                    if (res.data.success == true) {
                        this.products = res.data.data;
                        response.clearErrors();
                    }
                    this.loading = false;
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        fetchAll(payload) {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get(this.apiRoute, { params: payload }).then(res => {
                    if (res.data.success == true) {
                        this.list = res.data.data;
                        response.clearErrors();
                    }
                    this.loading = false;
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        fetchData(payload) {
            this.loading = true;
            this.setView();
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get(this.apiRoute + '/' + payload).then(res => {
                    this.data = res.data.data;
                    this.form = res.data.data;
                    response.clearErrors();
                    this.loading = false;
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        validateVoucher(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get('/proficiencies/queries/vouchers/' + payload).then(res => {
                    this.form.voucher = res.data.data;
                    response.clearErrors();
                    resolve(res.data);
                }).catch(err => {
                    this.form.voucher = null
                    reject(err);
                });
            })
        },
        checkout(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.post(this.apiRoute, payload).then(res => {
                    response.clearErrors();
                    resolve(res.data);
                }).catch(err => {
                    reject(err);
                });
            });
        },
        submitForm(method, payload) {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios({
                    method: method,
                    url: method == "POST" ? this.apiRoute : this.apiRoute + '/' + this.data.id,
                    data: payload,
                }).then(res => {
                    this.data = res.data.data;
                    this.loading = false;
                    response.clearErrors();
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        remove(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.delete(this.apiRoute + '/' + payload).then(res => {
                    this.data = null;
                    this.loading = false;
                    response.clearErrors();
                    resolve(res.data)
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        generateProficiency(id, payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.post(this.apiRoute + '/' + id + '/generate-proficiency', payload).then(res => {
                    this.data = null;
                    this.loading = false;
                    response.clearErrors();
                    resolve(res.data)
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        cancelOrder(id) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.post(this.apiRoute + '/' + id + '/cancel-order').then(res => {
                    this.data = res.data.data;
                    this.form = res.data.data;
                    this.loading = false;
                    response.clearErrors();
                    resolve(res.data)
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        rollBack() {
            this.fetchData(this.data.id);
            this.setView();
        },
        setEdit() {
            this.edit = true;
            this.view = false;
            this.create = false;
        },
        setView() {
            this.edit = false;
            this.view = true;
            this.create = false;
        },
        setCreate() {
            this.edit = false;
            this.view = false;
            this.create = true;
        },
        setLoading(payload) {
            this.loading = payload;
        },
        resetForm() {
            this.form = {
                cart: null,
                sampleAddress: {
                    name: null,
                    msisdn: null,
                    type: null,
                    latitude: null,
                    longitude: null,
                    street: null,
                    place: null,
                },
                invoiceAddress: {
                    name: null,
                    msisdn: null,
                    type: null,
                    latitude: null,
                    longitude: null,
                    street: null,
                    place: null,
                },
                paymentMethod: { id: 'direct-payment', name: 'Direct Payment' },
            };
        },
        resetFilter() {
            this.filter = {
                company: null,
                order: null,
                quotation: null,
                sales: null,
                contact: null,
                warehouse: null,
                refference: null,
                term: null,
                'createdAt.from': null,
                'createdAt.to': null
            };
        },
        addToCart(payload) {
            if (this.form.cart == null) this.form.cart = [];
            const isProductAlreadyInCart = this.form.cart.some(item => item.id === payload.id);
            if (!isProductAlreadyInCart) this.form.cart.push(payload);
        },
        removeFromCart(payload) {
            const indexToRemove = this.form.cart.findIndex(item => item.id === payload.id);
            if (indexToRemove !== -1)
                this.form.cart.splice(indexToRemove, 1);
        },
        getOriginalPrice(payload) {
            if (payload.bundled) {
                return payload.bundled.reduce((acc, item) => acc + item.price, 0)
            }
            return payload.price;
        },
        getDiscountPrice(payload) {
            if (payload.bundled) {
                return payload.bundled.reduce((acc, item) => acc + item.price, 0) - payload.price;
            }
            return 0;
        }
    },

    getters: {
        cartOriginalAmount() {
            let total = 0;
            if (this.form.cart != null) {
                this.form.cart.forEach(item => {
                    total += this.getOriginalPrice(item)
                });
            }

            return total;
        },
        cartDiscount() {
            let total = 0;
            if (this.form.cart != null) {
                this.form.cart.forEach(item => {
                    total += this.getDiscountPrice(item)
                });
            }

            return total;
        },
        cartTotal() {
            let total = 0;
            if (this.form.cart != null) {
                this.form.cart.forEach(item => {
                    total += item.price
                });
                if (this.voucherTotal > 0) {
                    total -= this.voucherTotal
                    if (total < 0) {
                        total = 0
                    }
                }
            }

            return total;
        },

        voucherTotal() {
            const type = this.form?.voucher?.type?.id
            const value = this.form?.voucher?.value;
            if (!type || !value) {
                return 0
            }
            let cartTotal = 0;
            if (this.form.cart != null) {
                this.form.cart.forEach(item => {
                    cartTotal += item.price
                });
            }
            if (type == 'percentage') {
                return (cartTotal * value) / 100;
            } else {
                if (cartTotal - value < 0) {
                    return cartTotal
                }
                return value
            }
        },

        cartCount() {
            if (this.form.cart != null)
                return this.form.cart.length
            return 0;
        },
        countFilter() {
            if (this.filter != null)
                return Object.values(this.filter).filter(val => val != null).length
            return 0;
        },
        isCreate() {
            return this.create ? true : false;
        },
        isEdit() {
            return this.edit ? true : false;
        },
        isView() {
            return this.view ? true : false;
        }
    }

});


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useModuleProficiencyOrderStore, import.meta.hot))
}
