const links = import.meta.glob('/modules/**/Vue/components/*dashboard-link.vue', { eager: true })
const NavLink = {};

for (let name in links) {
    let path = name.replace(/(\.\/|\.vue)/g, "");
    let filename = path.substring(path.lastIndexOf('/') + 1);
    NavLink[filename] = links[name].default;
}

export default NavLink
