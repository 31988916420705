<script>
export default {
    props: {
        type: String,
    },
    data() {
        return {
            navigations: [
                {
                    title: 'Event',
                    permission: 'module.event',
                    children: [

                        {
                            title: 'Event',
                            link: '/dashboard/events',
                            icon: 'CalendarIcon',
                            permission: 'module.event',
                        },
                        //add link here ...

                    ],
                },
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <PartialsDashboardLink :navigations="navigations" :type="type" />
</template>