<script>
export default {
    props: {
        type: String,
    },
    data() {
        return {
            navigations: [
                {
                    title: 'Location',
                    permission: 'module.location',
                    children: [

                        // {
                        //     title: 'Country',
                        //     link: '/dashboard/locations/countries',
                        //     icon: 'WorldIcon',
                        //     permission: 'module.location.country',
                        // },

                        {
                            title: 'Province',
                            link: '/dashboard/locations/provinces',
                            icon: 'BuildingBankIcon',
                            permission: 'module.location.province',
                        },

                        {
                            title: 'City',
                            link: '/dashboard/locations/cities',
                            icon: 'BuildingCommunityIcon',
                            permission: 'module.location.city',
                        },

                        {
                            title: 'District',
                            link: '/dashboard/locations/districts',
                            icon: 'BuildingEstateIcon',
                            permission: 'module.location.district',
                        },

                        {
                            title: 'Village',
                            link: '/dashboard/locations/villages',
                            icon: 'HomeIcon',
                            permission: 'module.location.village',
                        },
                        //add link here ...
                    ],
                },
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <PartialsDashboardLink :navigations="navigations" :type="type" />
</template>
