import { $getStorage, $setStorage, $getSession, $setSession } from '~/helpers/storage'

export const useResponseStore = defineStore('response', {
    state: () => ({
        responses: null,
        errors: null,
        hasResponse: true,
        setLoading: true,
    }),

    actions: {
        startRequest() {
            if (this.setLoading)
                this.hasResponse = false;
        },
        endRequest() {
            this.hasResponse = true;
            this.setLoading = true;
        },
        setErrors(payload) {
            this.errors = payload;
        },
        clearErrors() {
            this.errors = null;
        },
        setResponse(payload) {
            this.responses = payload;
        },
        noBlocking() {
            this.setLoading = false;
        },
        setStorage(key, value) {
            $setStorage(key, value);
        },
        setSession(key, value) {
            $setSession(key, value);
        },
        getStorage(key) {
            return $getStorage(key);
        },
        getSession(key) {
            return $getSession(key);
        },
        showToastDeleted() {
            this.$swal({
                icon: 'warning',
                position: 'top-end',
                showConfirmButton: false,
                text: this.t('Data Successfully Deleted'),
                timer: 3000,
                timerProgressBar: true,
                toast: true,
            })
        },
        showToast(message) {
            // this.$swal({
            //     icon: 'success',
            //     position: 'top-end',
            //     showConfirmButton: false,
            //     text: message,
            //     timer: 3000,
            //     timerProgressBar: true,
            //     toast: true,
            //     showCloseButton: true,
            // });
        }
    },
    getters: {
        hasError(state) {
            return state.errors ? true : false;
        },
        error(state) {
            return state.errors;
        },
        htmlError(state) {
            let text = '<p>';
            for (const props in state.errors) {
                let line = state.errors[props] + '<br/>'
                text = text + line;
            };
            return text + '</p>';
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useResponseStore, import.meta.hot))
}
