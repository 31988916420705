<script>
export default {
    props: {
        type: String,
    },
    data() {
        return {
            navigations: [
                {
                    title: 'Proficiency Test',
                    permission: ['module.proficiency', 'module.proficiency.order'],
                    children: [
                        {
                            title: 'New Order',
                            link: '/dashboard/proficiencies/orders/create',
                            icon: 'ShoppingCartPlusIcon',
                            permission: 'module.proficiency.order',
                        },
                        {
                            title: 'Order List',
                            link: '/dashboard/proficiencies/orders',
                            icon: 'LicenseIcon',
                            permission: 'module.proficiency.order',
                        },
                        {
                            title: 'Proficiency Report',
                            link: '/dashboard/proficiencies',
                            icon: 'TestPipeIcon',
                            permission: 'module.proficiency',
                        },
                        {
                            title: 'Proficiency Line',
                            link: '/dashboard/proficiencies/lines',
                            icon: 'FileReportIcon',
                            permission: 'module.proficiency.line',
                        },
                        {
                            title: 'Report Interim',
                            link: '/dashboard/proficiencies/reports',
                            icon: 'MathIcon',
                            permission: 'module.proficiency.report',
                        },
                        {
                            title: 'Certificate',
                            link: '/dashboard/proficiencies/certificates',
                            icon: 'CertificateIcon',
                            permission: 'module.proficiency',
                        },
                        {
                            title: 'Technique',
                            link: '/dashboard/proficiencies/techniques',
                            icon: 'ToolIcon',
                            permission: 'module.proficiency.technique',
                        },
                        {
                            title: 'Methodology',
                            link: '/dashboard/proficiencies/methods',
                            icon: 'BookmarkEditIcon',
                            permission: 'module.proficiency.method',
                        },
                        {
                            title: 'Standard',
                            link: '/dashboard/proficiencies/standards',
                            icon: 'VocabularyIcon',
                            permission: 'module.proficiency.standard',
                        },
                        //add link here ...
                    ],
                },
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <PartialsDashboardLink :navigations="navigations" :type="type" />
</template>
