<script>
export default {
    setup() {
        const auth = useAuthStore();
        const can = auth.can;
        const module = useModuleStore();

        return { auth, can, module };
    },
    data() {
        return {
            tabs: [
                {
                    title: 'Home',
                    link: '/dashboard',
                    icon: 'HomeIcon',
                    // permission: 'setting.bank',
                },
                {
                    title: 'Invoice',
                    link: '/dashboard/proficiencies/orders',
                    icon: 'LicenseIcon',
                    permission: 'module.proficiency.order',
                },
                {
                    title: 'Test',
                    link: '/dashboard/proficiencies',
                    icon: 'TestPipeIcon',
                    permission: 'module.proficiency',
                },
                //add tabs here ...

            ]
        }
    },
    methods: {
        isShow(payload) {
            let show = true;
            if (payload.module) {
                payload.module.forEach(module => {
                    if (show && this.module.checkModule(module) == false) show = false;
                });
            }

            if (payload.permission) {
                if (show && this.can(payload.permission + '-index') == false) show = false;
            }
            return show;
        }
    }
}
</script>

<template>
    <footer class="uk-hidden@s uk-position-bottom uk-padding-medium uk-width-expand uk-background-default"
        style="z-index: 10;">
        <nav class="uk-navbar-container uk-navbar-transparent uk-flex" uk-navbar>
            <div class="uk-float-left uk-margin-left uk-margin-right uk-text-center">
                <RouterLink to="/dashboard/menu" class="uk-button uk-button-default uk-padding-remove uk-margin-remove">
                    <Menu2Icon class="uk-padding-remove uk-margin-remove" />
                </RouterLink>
                <div class="uk-navbar-subtitle">{{ $t('Menu') }}</div>
            </div>
            <div class="uk-width-expand uk-flex uk-flex-center">
                <div class="uk-navbar-center">
                    <ul class="uk-navbar-nav uk-text-center">
                        <template v-for="tab in tabs" :key="tab.link">
                            <li v-if="isShow(tab)">
                                <div>
                                    <RouterLink :to="tab.link"
                                        class="uk-button uk-button-default uk-padding-remove uk-margin-remove">
                                        <component v-bind:is="tab.icon" class="uk-padding-remove uk-margin-remove" />
                                    </RouterLink>
                                    <div class="uk-navbar-subtitle">{{ $t(tab.title) }}</div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="uk-float-right uk-margin-left uk-margin-right uk-text-center">
                <RouterLink to="/dashboard/settings/profile"
                    class="uk-button uk-button-default uk-padding-remove uk-margin-remove">
                    <UserIcon class="uk-padding-remove uk-margin-remove" />
                </RouterLink>
                <div class="uk-navbar-subtitle">{{ $t('Profile') }}</div>
            </div>
        </nav>
    </footer>
</template>
