import axios from 'axios';
import Router from '~/router';

let headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
};

const $axios = axios.create({
    baseURL: import.meta.env.VITE_API_ENDPOINT,
    withCredentials: true,
    headers: headers
});

//Add Bearer Token
$axios.interceptors.request.use(config => {
    const auth = useAuthStore();
    const response = useResponseStore();
    response.startRequest();
    if (auth.token) config.headers['X-Authorization'] = 'Bearer ' + auth.token;
    if (auth.company?.id) config.headers['X-Company'] = auth.company?.id;
    if (auth.branch?.id) config.headers['X-Branch'] = auth.branch?.id;
    return config;
});

$axios.interceptors.response.use(response => {
    const encrypted = response?.headers?.get('X-Signature');
    if (encrypted) {
        const content = atob(response.data);
        response.data = JSON.parse(content);
    }
    const responseStore = useResponseStore();
    responseStore.endRequest();
    return response;
}, error => {
    //Add Middleware if Bearer Token Expired / Forbidden Auth
    const auth = useAuthStore();
    const response = useResponseStore();
    response.endRequest();
    const encrypted = error?.response?.headers?.get('X-Signature');
    if (encrypted) {
        const content = atob(error.response.data);
        error.response.data = JSON.parse(content);
    }
    if (error) {
        if (error.code == 400 || (error.response && error.response.status == 400)) {
            if (error.response && error.response.data.message.error) {
                response.setErrors(error.response.data.message.error);
            } else if (error.message.error) {
                response.setErrors(error.message.error);
            };
        } else if (error.response) {
            switch (error.response.status) {
                case 401:
                    auth.logout();
                    if (document.location.pathname != "/auths/login")
                        document.location.href = "/auths/login";
                    break;
                case 403:
                    response.setErrors({ server: "This action is unauthorized" });
                    break;
                case 406:
                    Router.replace('/not-found')
                    break;
                case 503:
                    location.reload();
                    break;
                default:
                    if (error.response.status == 500 || error.response.status == 422) {
                        if (!error.response.data) {
                            response.setErrors({ server: error.message });
                            return Promise.reject(error.message);
                        }
                        if (!error.response.data.message) {
                            response.setErrors({ server: error.message });
                            return Promise.reject(error.message);
                        }
                        if (!error.response.data.message.error) {
                            response.setErrors({ server: error.message });
                            return Promise.reject(error.message);
                        } else {
                            response.setErrors({ server: error.response.data.message.error });
                            return Promise.reject(error.message);
                        }
                    }
                    response.setErrors({ server: error.message });
                    return Promise.reject(error.message);
            }
            return Promise.reject(error.message);
        }
        return Promise.reject(error.message);
    }
});

export default $axios;
