<script>
import NavLink from '~/helpers/navmodule.js'

export default {
    setup() {
        const auth = useAuthStore();
        const module = useModuleStore();
        const can = auth.can;
        return { auth, can, module };
    },
    components: NavLink,
    data() {
        return {
            navigations: [
                {
                    title: 'My Account',
                    permission: 'setting.profile',
                    children: [
                        {
                            title: 'Profile',
                            link: '/dashboard/settings/profile',
                            icon: 'UserCircleIcon',
                            permission: 'setting.profile',
                        },
                    ]
                },
                {
                    title: 'App & Setting',
                    permission: 'setting',
                    children: [
                        {
                            title: 'Configuration',
                            link: '/dashboard/settings/configurations',
                            icon: 'SettingsIcon',
                            permission: 'setting.configuration',
                        },
                        {
                            title: 'Sequence',
                            link: '/dashboard/settings/sequences',
                            icon: 'DialpadIcon',
                            permission: 'setting.sequence',
                        },
                        {
                            title: 'Bank',
                            link: '/dashboard/settings/banks',
                            icon: 'BuildingBankIcon',
                            permission: 'setting.bank',
                        },
                        {
                            title: 'Courier',
                            link: '/dashboard/settings/couriers',
                            icon: 'TruckDeliveryIcon',
                            permission: 'module.raja.ongkir.courier',
                        },
                        {
                            title: 'Module',
                            link: '/dashboard/settings/modules',
                            icon: 'AppsIcon',
                            permission: 'setting.module',
                        },
                        {
                            title: 'Accessibility',
                            link: '/dashboard/settings/accessibilities',
                            icon: 'CloudLockIcon',
                            permission: 'setting.accessibility',
                        },
                        {
                            title: 'Slider',
                            link: '/dashboard/settings/sliders',
                            icon: 'PhotoIcon',
                            permission: 'setting.slider',
                        },
                        {
                            title: 'Audit Logs',
                            link: '/dashboard/activities',
                            icon: 'SettingsIcon',
                            permission: 'setting.configuration',
                        },
                    ],
                },
            ]
        }
    }
}
</script>

<template>
    <ul ref="navigation" v-if="module.module && Object.keys(module.module).length > 0" uk-nav class="uk-width-1-1">
        <li v-if="!auth.permission || auth?.permission?.length == 0" class="uk-text-center">
            <div class="uk-spinner uk-text-center" uk-spinner="ratio: 3"></div>
        </li>
        <template v-else-if="auth?.permission?.length > 0">
            <template v-for="value in module.module" :key="value.name">
                <component :is="`${value.lowerName}-dashboard-link`" v-if="value.isEnabled" />
            </template>
        </template>

        <template v-for="(tab, index) in navigations" :key="index">
            <li v-if="can(`${tab.permission}-index`)" class="uk-nav-header uk-margin-remove-top">
                <span class="navigation-side-label"><b>{{ $t(tab.title) }}</b></span>
            </li>
            <template v-for="tab in tab.children" :key="tab.link">
                <li v-if="can(`${tab.permission}-index`)" class="link"
                    :class="{ 'active': `${$route.path}`.search(tab.link) >= 0 }">
                    <RouterLink :to="tab.link">
                        <span class="uk-padding-small uk-padding-remove-horizontal uk-flex uk-flex-middle">
                            <component v-bind:is="tab.icon" />
                        </span>
                        <span class="navigation-side-label">{{ $t(tab.title) }}</span>
                    </RouterLink>
                </li>
            </template>
        </template>
        <div class="uk-padding uk-hidden@s"></div>
    </ul>
</template>
