import $axios from '~/helpers/axios';

const blankData = {
    key: null,
    type: null,
    value: null,
};

export const useConfigurationStore = defineStore('configuration', {
    state: () => ({
        moduleName: 'Configuration',
        permission: 'setting.configuration',
        apiRoute: '/settings/configurations',
        route: '/dashboard/settings/configurations',
        data: blankData,
        list: null,
        create: false,
        edit: false,
        view: true,
        loading: true,
        form: blankData,
        imageUrl: null,
        options: {
            type: ['string', 'integer', 'boolean', 'json', 'text', 'richtext', 'date', 'datetime', 'array'],
        },
        filter: {

        },
        exportFields: [
            { 'Key': 'Key' },
            { 'type': 'Tye' },
            { 'value': 'Value' },
        ],
        imageLink: null,
    }),

    actions: {
        fetchAll(payload) {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get(this.apiRoute, { params: payload }).then(res => {
                    if (res.data.success == true) {
                        this.list = res.data.data;
                        response.clearErrors();
                    }
                    this.loading = false;
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        fetchData(payload) {
            this.loading = true;
            this.setView();
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get(this.apiRoute + '/' + payload).then(res => {
                    this.data = res.data.data;
                    this.form = res.data.data;
                    this.imageUrl = this.form?.imageUrl;
                    if(this.data?.type == 'image') {
                        this.imageLink = this.data?.value
                    }
                    response.clearErrors();
                    this.loading = false;
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        submitForm(method, payload) {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios({
                    method: method,
                    url: method == "POST" ? this.apiRoute : this.apiRoute + '/' + this.data.id,
                    data: payload,
                }).then(res => {
                    this.data = res.data.data;
                    this.loading = false;
                    response.clearErrors();
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        remove(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.delete(this.apiRoute + '/' + payload).then(res => {
                    this.data = null;
                    this.loading = false;
                    response.clearErrors();
                    resolve(res.data)
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        rollBack() {
            this.fetchData(this.data.id);
            this.setView();
        },
        setEdit() {
            this.edit = true;
            this.view = false;
            this.create = false;
        },
        setView() {
            this.edit = false;
            this.view = true;
            this.create = false;
        },
        setCreate() {
            this.edit = false;
            this.view = false;
            this.create = true;
            this.data = null;
            this.loading = false;
            this.resetForm();
        },
        setLoading(payload) {
            this.loading = payload;
        },
        resetForm() {
            this.form = {
                key: null,
                type: null,
                value: null,
            };
        },
        resetFilter() {
            this.filter = {

            };
        },
        get(payload) {
            //filter key from list
            const config = this.list ? this.list.find(item => item.key == payload) : null;
            if (config)
                return config.value;
            return null;
        }
    },

    getters: {
        countFilter() {
            if (this.filter != null)
                return Object.values(this.filter).filter(val => val != null).length
            return 0;
        },
        isCreate() {
            return this.create ? true : false;
        },
        isEdit() {
            return this.edit ? true : false;
        },
        isView() {
            return this.view ? true : false;
        }
    }

});


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSliderStore, import.meta.hot))
}
