<script>
import $axios from '~/helpers/axios';
import { $getSession, $setSession } from '~/helpers/storage';

export default {
    setup() {
        const response = useResponseStore();
        const helpers = useHelperStore();
        const { t } = useI18n();
        return { response, helpers, t };
    },
    props: {
        source: String,
        titlecase: {
            type: Boolean,
            default: false,
        },
        reduced: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        taggable: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        noLabel: {
            type: Boolean,
            default: false,
        },
        selectLabel: {
            type: String,
            default: 'name',
        },
        modelValue: [Object, String, Number],
        name: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: null,
        },
        options: {
            type: Array,
            default: [],
        },
        noprefill: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            computedOptions: this.options,
            searching: false,
            focused: false,
        }
    },
    computed: {
        textView() {
            let textView = [];
            if (this.modelValue == null || (this.modelValue instanceof Array && this.modelValue.length == 0)) return ['-'];
            if (this.modelValue instanceof Object && this.modelValue.hasOwnProperty('id')) {
                if (this.modelValue[this.selectLabel]) textView.push(this.t(this.modelValue[this.selectLabel]));
            } else if (this.modelValue instanceof Array) {
                this.modelValue.forEach(item => {
                    if (item instanceof Object && item.hasOwnProperty('id')) {
                        if (item[this.selectLabel]) textView.push(this.t(item[this.selectLabel]));
                    } else {
                        if (item) textView.push(this.t(item));
                    }
                })
            } else if (this.modelValue) {
                textView.push(this.t(this.modelValue));
            }

            return textView;
        },
        _label() {
            if (this.label) {
                return this.label;
            } else if (this.name) {
                return this.t(this.helpers.headline(this.name))
            } else {
                return null;
            }
        },
        _placeholder() {
            if (this.placeholder) {
                return this.placeholder;
            } else if (this._label != null) {
                return this.t('Please Choose') + ' ' + this.t(this.helpers.headline(this._label));
            } else {
                return this.t('Please Type To Search . . .');
            }
        }
    },

    methods: {
        getPrefill() {
            if (!this.focused) this.prefill()
            this.focused = true;
        },
        onSearch(search, loading) {
            if (search?.length > 0) {
                this.searching = true;
                this.removeWarning();
                loading(true);
                this.Search(loading, search, this);
            }
        },
        Search: _.debounce((loading, search, vm) => {
            $axios.get(vm.source, { params: { search: search } })
                .then(res => {
                    vm.computedOptions = res.data.data;
                    vm.searching = false;
                    $setSession(`select:${vm.source}`, vm.computedOptions);
                    loading(false);
                });
        }, 350),
        async prefill() {
            this.response.noBlocking();
            if (this.source) {
                const cache = $getSession(`select:${this.source}`);
                if (cache) {
                    this.computedOptions = cache;
                } else {
                    await $axios.get(this.source)
                        .then(res => {
                            this.computedOptions = res.data.data;
                            $setSession(`select:${this.source}`, this.computedOptions);
                        });
                }
            }
        },
        updateValue(value) {
            this.removeWarning();
            if (this.reduced) {
                this.$emit('update:modelValue', value.id)
            } else {
                this.$emit('update:modelValue', value)
            }
        },
        removeValue(value) {
            this.removeWarning();
            if (value instanceof Object && value.hasOwnProperty('id')) {
                const newValue = this.modelValue.filter(function (item) { return item.id !== value.id });
                this.$emit('update:modelValue', newValue);
            } else if (value) {
                const newValue = this.modelValue.filter(function (item) { return item !== value });
                this.$emit('update:modelValue', newValue);
            }
        },
        removeWarning() {
            if (this.response?.errors?.hasOwnProperty(this.name)) delete this.response.errors[this.name];
            if (this.response?.errors?.hasOwnProperty(`${this.name}.id`)) delete this.response.errors[`${this.name}.id`];
        },
        optionCreated(value) { },
    },
    watch: {
        source: {
            handler() {
                this.prefill();
            },
        }
    }
}
</script>


<template>
    <div>
        <template v-if="_label && !noLabel">
            <div v-if="disabled" class="uk-form-label uk-text-capitalize">
                {{ _label }}
            </div>
            <label v-else class="uk-form-label uk-text-capitalize" :for="name">
                {{ _label }}
                <span v-if="required" class="uk-text-danger">(*)</span>
                <div uk-spinner="ratio: 0.7" class="uk-float-right" v-if="!response.hasResponse" />
            </label>
        </template>
        <div @mouseover="getPrefill" v-if="!disabled" class="uk-form-controls uk-inline uk-width-1-1">
            <v-select ref="select2" v-if="source && !reduced" :class="{
                'uk-form-small': !multiple
            }" :inputId="name" :label="selectLabel" :modelValue="modelValue" :options="computedOptions"
                @search="onSearch" :placeholder="_placeholder" :multiple="multiple" :taggable="taggable"
                @option:selected="updateValue" @option:deselected="removeValue" @option:created="optionCreated"
                :disabled="!response.hasResponse && !searching">
                <!-- <template #option="{ name }">
                    <div class="quill-container" v-html="$t(name)" />
                </template>
                <template #selected-option="{ name }">
                    <div class="quill-container" v-html="$t(name)" />
                </template> -->
            </v-select>
            <v-select ref="select2" v-else-if="source && reduced" :class="{
                'uk-form-small': !multiple
            }" :inputId="name" :label="selectLabel" :modelValue="modelValue" :options="computedOptions"
                @search="onSearch" :placeholder="_placeholder" :reduce="computedOptions => computedOptions.id"
                :multiple="multiple" :taggable="taggable" @option:selected="updateValue" @option:created="optionCreated"
                :disabled="!response.hasResponse && !searching">
                <!-- <template #option="{ name }">
                    <div class="quill-container" v-html="$t(name)" />
                </template>
                <template #selected-option="{ name }">
                    <div class="quill-container" v-html="$t(name)" />
                </template> -->
            </v-select>
            <v-select ref="select2" v-else-if="!source" :class="{
                'uk-form-small': !multiple
            }" :inputId="name" :label="selectLabel" :modelValue="modelValue" :options="computedOptions"
                :placeholder="_placeholder" :multiple="multiple" :taggable="taggable" @option:selected="updateValue"
                @option:deselected="removeValue" @option:created="optionCreated"
                :disabled="!response.hasResponse && !searching">
                <!-- <template #option="{ name }">
                    <div class="quill-container" v-html="$t(name)" />
                </template>
                <template #selected-option="{ name }">
                    <div class="quill-container" v-html="$t(name)" />
                </template> -->
            </v-select>
            <span class="uk-form-icon uk-form-icon-flip uk-margin-right"
                v-if="response.errors && (response.errors[`${name}.id`] || response.errors[name])">
                <AlertCircleIcon color='red' />
            </span>
        </div>
        <div v-else class="uk-form-controls uk-inline uk-width-1-1">
            <p class="uk-margin-remove uk-text-capitalize">
                <template v-if="multiple">
                    <span class="uk-badge uk-margin-small-right" v-for=" text  in  textView " :key="text" uk-badge
                        v-html="text" />
                </template>
                <template v-else-if="textView.length > 0">
                    {{ textView.join(', ') }}
                </template>
            </p>
        </div>

        <div v-if="response.errors && (response.errors[`${name}.id`] || response.errors[name])">
            <div class="uk-link-reset uk-text-small uk-text-danger">
                <template v-if="response.errors && response.errors[`${name}.id`]">
                    {{ response.errors[`${name}.id`].toString() }}
                </template>
                <template v-else>
                    {{ response.errors[name].toString() }}
                </template>
            </div>
        </div>
    </div>
</template>
