import $axios from '~/helpers/axios';
import { jwtDecode } from "jwt-decode";
import * as Sentry from '@sentry/vue';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: null,
        version: null,
        permission: null,
        role: null,
        companies: null,
        company: null,
        branches: null,
        branch: null,
        division: null,
        documentations: null,
    }),

    actions: {
        resendVerification(payload) {
            return new Promise((resolve, reject) => {
                $axios.post('/auths/verify/resend', payload)
                    .then(res => {
                        this.loading = false;
                        resolve(res.data)
                    }).catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            })
        },
        login(payload) {
            return new Promise((resolve, reject) => {
                $axios.post('/auths/login', payload)
                    .then(res => {
                        if (res.data.success === true) this.setSession(res);
                        this.loading = false;
                        resolve(res.data)
                    }).catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            })
        },
        loginAsUser(payload) {
            return new Promise((resolve, reject) => {
                $axios.post('/auths/login-as-user', payload)
                    .then(res => {
                        if (res.data.success === true) {
                            this.logout()
                            this.setSession(res);
                        }
                        this.loading = false;
                        resolve(res.data)
                    }).catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            })
        },

        register(payload) {
            return new Promise((resolve, reject) => {
                $axios.post('/auths/register', payload)
                    .then(res => {
                        this.loading = false;
                        resolve(res.data)
                    }).catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            })
        },

        requestPassword(payload) {
            return new Promise((resolve, reject) => {
                $axios.post('/auths/passwords/forgot', payload)
                    .then(res => {
                        this.loading = false;
                        resolve(res.data)
                    }).catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            })
        },

        resetPassword(payload) {
            return new Promise((resolve, reject) => {
                $axios.post('/auths/passwords/reset', payload)
                    .then(res => {
                        this.loading = false;
                        resolve(res.data)
                    }).catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            })
        },

        validateToken(payload) {
            return new Promise((resolve, reject) => {
                $axios.post('/auths/passwords/validate', payload)
                    .then(res => {
                        this.loading = false;
                        resolve(res.data)
                    }).catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            })
        },

        validateVerifyToken(payload) {
            return new Promise((resolve, reject) => {
                $axios.post('/auths/verify', payload)
                    .then(res => {
                        this.loading = false;
                        resolve(res.data)
                    }).catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            })
        },

        activateAccount(payload) {
            return new Promise((resolve, reject) => {
                $axios.post('/auths/verify/activate', payload)
                    .then(res => {
                        this.loading = false;
                        resolve(res.data)
                    }).catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            })
        },

        logout() {
            this.token = null;
            this.version = null;
            this.permission = null;
            this.role = null;
            this.companies = null;
            this.company = null;
            this.branches = null;
            this.branch = null;
            this.division = null;
            localStorage.clear();
            sessionStorage.clear();
        },
        getPermission() {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get('/auths/permission')
                    .then(res => {
                        if (res.data.success == true) {
                            this.permission = res.data.data;
                            response.setStorage('permissions', this.permission);
                        }
                        resolve(res.data)
                    }).catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            })
        },
        setSession(res) {
            const response = useResponseStore();
            this.token = res.data?.data?.token;
            response.setStorage('token', this.token);
            this.version = res.data?.data?.version;
            response.setStorage('version', this.version);
            this.company = res.data?.data?.company;
            response.setStorage('company', this.company);
            this.companies = res.data?.data?.companies;
            response.setStorage('companies', this.companies);
            this.branch = res.data?.data?.branch;
            response.setStorage('branch', this.branch);
            this.branches = res.data?.data?.branches;
            response.setStorage('branches', this.branches);
            this.role = this.roles;
            response.setStorage('roles', this.roles);
            this.getPermission();
            Sentry.setUser({
                email: this.user.email,
                roles: this.roles
            });
        },
        loadSession() {
            const response = useResponseStore();
            this.token = response.getStorage('token');
            this.version = response.getStorage('version');
            this.company = response.getStorage('company');
            this.companies = response.getStorage('companies');
            this.branch = response.getStorage('branch');
            this.role = response.getStorage('roles');
            this.branches = response.getStorage('branches');
            if (this.token && response.getStorage('permissions') == null) {
                this.getPermission().catch(err => { });
            }
            this.permission = response.getStorage('permissions');

            //Module Company
            const module = useModuleStore();
            if (module.check('company') && (this.company == null || this.branch == null)) {
                this.getMe().catch(err => { });
            }
        },
        getMe() {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get('/auths/me')
                    .then(res => {
                        if (res.data.success == true) {
                            this.branches = res.data.data.branches;
                            this.companies = res.data.data.companies;
                            response.setStorage('companies', this.companies);
                            response.setStorage('branches', this.branches);

                            if (this.branches?.length > 0) {
                                const defaultBranch = this.branches.filter(c => c.isDefault == true);
                                if (defaultBranch?.length > 0) {
                                    this.branch = defaultBranch[0];
                                } else {
                                    this.branch = this.branches[0];
                                }
                                if (this.companies?.length > 0) {
                                    const defaultCompany = this.companies.filter(c => c.isDefault == true);
                                    if (defaultCompany?.length > 0) {
                                        this.company = defaultCompany[0];
                                    } else {
                                        this.company = this.companies[0];
                                    }
                                } else {
                                    this.getCompany();
                                }
                            } else {
                                this.getCompany();
                            }
                        }
                        resolve(res.data)
                    })
                    .catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getCompany() {
            const response = useResponseStore();
            const params = {
                skipPagination: true,
                isActive: true,
            }
            return new Promise((resolve, reject) => {
                $axios.get('auths/me/companies', { params: params })
                    .then(res => {
                        if (res.data.success == true) {
                            if (res.data.data.length > 0 && this.level >= 4) {
                                this.company = res.data.data[0];
                                response.setStorage('company', this.company);
                            }
                        }
                        resolve(res.data)
                    })
                    .catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getBranch() {
            const response = useResponseStore();
            const params = {
                company: this.company?.id,
                isActive: true,
            }
            return new Promise((resolve, reject) => {
                $axios.get('/auths/me/branches', { params: params })
                    .then(res => {
                        if (res.data.success == true) {
                            if (res.data.data.length > 0) {
                                this.branches = res.data.data;
                                this.branch = res.data.data[0];
                                response.setStorage('branches', this.branches);
                                response.setStorage('branch', this.branch);
                            } else {
                                this.branches = null;
                                this.branch = null;
                            }
                        }
                        resolve(res.data)
                    })
                    .catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        getDocumentation() {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get('/documentations')
                    .then(res => {
                        if (res.data.success == true) {
                            this.documentations = res.data.data;
                        }
                        resolve(res.data)
                    })
                    .catch(err => {
                        this.loading = false;
                        reject(err);
                    });
            });

        }
    },

    getters: {
        authToken(state) {
            return state.token;
        },
        hasSession(state) {
            return state.token ? true : false;
        },
        user(state) {
            return state.token ? jwtDecode(state.token).user : null;
        },
        roles(state) {
            return state.token ? jwtDecode(state.token).roles : null;
        },
        level(state) {
            return state.token ? jwtDecode(state.token).level : null;
        },
        hasRoles: (state) => (roles) => {
            const can = state.roles ? state.roles.find(val => val === roles) : null;
            return can ? true : false;
        },
        permissions(state) {
            return state.permission;
        },
        can: (state) => (permission) => {
            const can = state.permission ? state.permission.find(val => val === permission) : null;
            return can ? true : false;
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
