import $axios from '~/helpers/axios';

const data = {
    data: null,
    readAt: null,
    createdAt: null,
    total: null
};

export const useNotificationStore = defineStore('notification', {
    state: () => ({
        moduleName: 'Notifications',
        permission: 'notification',
        apiRoute: '/notifications',
        route: '/dashboard/settings/profile/notifications',
        data: data,
        list: null,
        create: false,
        edit: false,
        view: true,
        loading: true,
        filterOptions: {
            status: [
                { id: "0", name: "Un - Read" },
                { id: "1", name: "Read" },
            ],
        },
        filter: {
            isRead: null
        }
    }),

    actions: {
        fetchAll(payload) {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get(this.apiRoute, { params: payload }).then(res => {
                    if (res.data.success == true) {
                        this.list = res.data.data;
                        this.total = res.data.total;
                        response.clearErrors();
                    }
                    this.loading = false;
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                })
            })
        },
        fetchData(payload) {
            this.loading = true;
            this.setView();
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get(this.apiRoute + '/' + payload).then(res => {
                    this.data = res.data.data;
                    response.clearErrors();
                    this.loading = false;
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                })
            })
        },
        markAsRead(payload) {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios({
                    method: 'PUT',
                    url: this.apiRoute + '/' + payload,
                }).then(res => {
                    resolve(res.data);
                    response.clearErrors();
                })
                this.loading = false;
            })
        },
        remove(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.delete(this.apiRoute + '/' + payload).then(res => {
                    this.data = null;
                    this.loading = false;
                    response.clearErrors();
                    resolve(res.data)
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                })
            })
        },
        rollBack() {
            this.fetchData(this.data.id);
            this.setView();
        },
        setEdit() {
            this.edit = true;
            this.view = false;
            this.create = false;
        },
        setView() {
            this.edit = false;
            this.view = true;
            this.create = false;
        },
        setCreate() {
            this.edit = false;
            this.view = false;
            this.create = true;
            this.data = null;
            this.loading = false;
        },
        setLoading(payload) {
            this.loading = payload;
        }
    },

    getters: {
        countFilter() {
            if (this.filter != null)
                return Object.values(this.filter).filter(val => val != null).length
            return 0;
        },
        isCreate() {
            return this.create ? true : false;
        },
        isEdit() {
            return this.edit ? true : false;
        },
        isView() {
            return this.view ? true : false;
        }
    }

});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
}
