<script>

export default {
    setup() {
        const { t } = useI18n();
        const auth = useAuthStore();
        const module = useModuleStore();
        const company = useModuleCompanyStore();
        const response = useResponseStore();
        return { auth, t, module, company, response }
    },
    computed: {
        companyName() {
            if (this.auth.company) return this.auth?.company?.name;
            return 'Select Company';
        },
        companyBranch() {
            if (this.auth.branch) return this.auth?.branch?.name;
            return 'Select Branch';
        },
        paramCompany() {
            if (this.auth.company) {
                return "?company=" + this.auth.company.id;
            }
            return "";
        }
    },
    watch: {
        'auth.company': {
            handler(payload) {
                if (!payload) return;
                this.response.setStorage('company', payload);
                this.auth.getBranch();
            }
        }
    }
}
</script>

<template>
    <template v-if="auth?.user?.role_level > 3">
        <button v-if="auth.user && (auth?.companies?.length > 0 || auth?.branches?.length > 0)"
            class="uk-button uk-button-small uk-button-default uk-padding-remove uk-text-right">
            <span class="uk-dark uk-text-small brand uk-visible@s">
                <b>{{ companyName }}</b>
                <template v-if="auth?.branches?.length > 0">
                    <br /><small>{{ companyBranch }}</small>
                </template>
            </span>
            <BuildingIcon class="uk-hidden@s" />
        </button>
        <div v-if="auth.companies?.length > 1 || auth.branches?.length > 1 || auth.level <= 1" uk-dropdown="mode: click;"
            class="uk-width-large">
            <div class="uk-grid-small uk-child-width-1-1" uk-grid>
                <FormSelect v-if="auth.level <= 1" name="company" v-model="auth.company" placeholder="Choose Company"
                    source="auths/me/companies" class="uk-width-1-1" />
                <FormSelect v-else-if="auth.companies?.length > 1" name="company" v-model="auth.company"
                    placeholder="Choose Company" :options="auth.companies" class="uk-width-1-1"
                    :disabled="auth.companies?.length == 1" />

                <FormSelect v-if="auth.branches != null" name="branch" v-model="auth.branch" placeholder="Choose Branch"
                    :source="`auths/me/branches${paramCompany}`" class="uk-width-1-1" />
            </div>
        </div>
    </template>
</template>
