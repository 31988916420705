import $axios from '~/helpers/axios';

const blankData = {
    title: null,
    description: null,
    startAt: null,
    endAt: null,
    isForever: false,
    isActive: true,
    hideTitle: false
};

export const useSliderStore = defineStore('slider', {
    state: () => ({
        moduleName: 'Login Slider',
        permission: 'setting.slider',
        apiRoute: '/sliders',
        route: '/dashboard/settings/sliders',
        data: blankData,
        list: null,
        create: false,
        edit: false,
        view: true,
        loading: true,
        form: blankData,
        imageUrl: null,
        filter: {

        },
        exportFields: [
            { 'title': 'Title' },
            { 'description': 'Description' },
            { 'startAt': 'Start At' },
            { 'endAt': 'End At' },
            { 'isForever': 'Forever?' },
            { 'isActive': 'Active?' }
        ],
    }),

    actions: {
        fetchAll(payload) {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get(this.apiRoute, { params: payload }).then(res => {
                    if (res.data.success == true) {
                        this.list = res.data.data;
                        response.clearErrors();
                    }
                    this.loading = false;
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        fetchData(payload) {
            this.loading = true;
            this.setView();
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.get(this.apiRoute + '/' + payload).then(res => {
                    this.data = res.data.data;
                    this.form = res.data.data;
                    this.imageUrl = this.form?.imageUrl;
                    response.clearErrors();
                    this.loading = false;
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        submitForm(method, payload) {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios({
                    method: method,
                    url: method == "POST" ? this.apiRoute : this.apiRoute + '/' + this.data.id,
                    data: payload,
                }).then(res => {
                    this.data = res.data.data;
                    this.loading = false;
                    response.clearErrors();
                    resolve(res.data);
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        remove(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios.delete(this.apiRoute + '/' + payload).then(res => {
                    this.data = null;
                    this.loading = false;
                    response.clearErrors();
                    resolve(res.data)
                }).catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        rollBack() {
            this.fetchData(this.data.id);
            this.setView();
        },
        setEdit() {
            this.edit = true;
            this.view = false;
            this.create = false;
        },
        setView() {
            this.edit = false;
            this.view = true;
            this.create = false;
        },
        setCreate() {
            this.edit = false;
            this.view = false;
            this.create = true;
            this.data = null;
            this.loading = false;
            this.resetForm();
        },
        setLoading(payload) {
            this.loading = payload;
        },
        resetForm() {
            this.form = {
                title: null,
                description: null,
                startAt: null,
                endAt: null,
                isForever: false,
                isActive: true,
                hideTitle: false
            };
            this.imageUrl = null;
        },
        resetFilter() {
            this.filter = {

            };
        },
    },

    getters: {
        countFilter() {
            if (this.filter != null)
                return Object.values(this.filter).filter(val => val != null).length
            return 0;
        },
        isCreate() {
            return this.create ? true : false;
        },
        isEdit() {
            return this.edit ? true : false;
        },
        isView() {
            return this.view ? true : false;
        }
    }

});


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSliderStore, import.meta.hot))
}
