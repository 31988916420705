<script>
export default {
    props: {
        type: String,
    },
    data() {
        return {
            navigations: [
                {
                    title: 'Document',
                    permission: 'module.document',
                    children: [
                        {
                            title: 'Document',
                            link: '/dashboard/documents',
                            icon: 'FileIcon',
                            permission: 'module.document',
                        },
                        {
                            title: 'Category',
                            link: '/dashboard/documents/categories',
                            icon: 'AdjustmentsIcon',
                            permission: 'module.document.category',
                        },
                        {
                            title: 'Tag',
                            link: '/dashboard/documents/tags',
                            icon: 'TagIcon',
                            permission: 'module.document.tag',
                        },
                        //add link here ...



                    ],
                },
                //add more nav here ...
            ]
        }
    }
}
</script>

<template>
    <PartialsDashboardLink :navigations="navigations" :type="type" />
</template>
