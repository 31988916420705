import $axios from '~/helpers/axios';

const blankData = {
    code: null,
    name: null,
    phone: null,
    email: null,
    website: null,
    vision: null,
    mission: null,
    isActive: false,
    isSubCompany: false,
    isDefault: false,
    address: {
        street: null,
        latitude: null,
        longitude: null,
        place: null,
    },
    image: null,
};

export const useModuleCompanyStore = defineStore("module.company", {
    state: () => ({
        moduleName: "Company",
        permission: "module.company",
        apiRoute: "/companies",
        route: "/dashboard/companies",
        data: blankData,
        form: blankData,
        imageLink: null,
        list: null,
        create: false,
        edit: false,
        view: true,
        loading: true,
        filterOptions: {
            companyType: [
                { id: "0", name: "Holding Company" },
                { id: "1", name: "Subsidiary" },
            ],
            status: [
                { id: "0", name: "Non - Active" },
                { id: "1", name: "Active" },
            ],
        },
        filter: {
            locationProvince: null,
            locationCity: null,
            locationDistrict: null,
            locationVillage: null,

            companyType: null,
            companyStatus: null,
            companyParent: null,
        },
        exportFields: [
            { companyType: "Holding Company" },
            { code: "Code" },
            { name: "Name" },
            { "address.street": "Address 1" },
            { "address.place.name": "Address 2" },
            { phone: "Phone" },
            { fax: "Fax" },
            { email: "Email" },
            { website: "Website" },
            { registrationNo: "Registration Number" },
            { registrationDate: "Registration Date" },
            { taxNo: "Tax Identity Number" },
            { isVatEnterprise: "Vat Enterprise?" },
            { isActive: "Active ?" },
            { "parent.name": "Holding Company" },
            { createdAt: "Created At" },
            { updatedAt: "Updated At" },
        ],
    }),

    actions: {
        fetchAll() {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios
                    .get(this.apiRoute)
                    .then((res) => {
                        if (res.data.success == true) {
                            this.list = res.data.data;
                            response.clearErrors();
                        }
                        this.loading = false;
                        resolve(res.data);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        fetchData(payload) {
            this.loading = true;
            this.setView();
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios
                    .get(this.apiRoute + "/" + payload)
                    .then((res) => {
                        this.data = res.data.data;
                        this.form = res.data.data;
                        this.form.image = null;
                        this.imageLink = this.form.media ? this.form.media.mediaUrl : null;
                        if (this.form.address == null) {
                            this.form.address = {
                                street: null,
                                latitude: null,
                                longitude: null,
                                place: null,
                            };
                        }
                        response.clearErrors();
                        this.loading = false;
                        resolve(res.data);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        fetchOption() {
            this.loading = true;
            this.setCreate();
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios
                    .options(this.apiRoute)
                    .then((res) => {
                        this.data = res.data.data;
                        response.clearErrors();
                        this.loading = false;
                        resolve(res.data);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        submitForm(method, payload) {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios({
                    method: method,
                    url:
                        method == "POST"
                            ? this.apiRoute
                            : this.apiRoute + "/" + this.data.id,
                    data: payload,
                })
                    .then((res) => {
                        this.data = res.data.data;
                        this.loading = false;
                        response.clearErrors();
                        resolve(res.data);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        remove(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios
                    .delete(this.apiRoute + "/" + payload)
                    .then((res) => {
                        this.data = null;
                        this.loading = false;
                        response.clearErrors();
                        resolve(res.data);
                    })
                    .catch((err) => {
                        this.loading = false;
                        reject(err);
                    });
            });
        },
        rollBack() {
            this.fetchData(this.data.id);
            this.setView();
        },
        setEdit() {
            this.edit = true;
            this.view = false;
            this.create = false;
        },
        setView() {
            this.edit = false;
            this.view = true;
            this.create = false;
        },
        setCreate() {
            this.edit = false;
            this.view = false;
            this.create = true;
            this.imageLink = null;
            this.loading = false;
            this.resetForm();
        },
        resetForm() {
            this.form = blankData;
            this.data = blankData;
        },
        setLoading(payload) {
            this.loading = payload;
        },
        resetFilter() {
            this.filter = {
                locationProvince: null,
                locationCity: null,
                locationDistrict: null,
                locationVillage: null,

                companyType: null,
                companyStatus: null,
                companyParent: null,
            };
        },
        requestVerification() {
            this.loading = true;
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios
                    .post(this.apiRoute + "/" + this.data.id + "/verify")
                    .then((res) => {
                        this.data = res.data.data;
                        response.clearErrors();
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
                this.loading = false;
            });
        },
        validateCompany() {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios
                    .post(this.apiRoute + "/" + this.data.id + "/validated")
                    .then((res) => {
                        response.clearErrors();
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        addAttachment(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios
                    .post(this.apiRoute + "/" + this.data.id + "/attachments", payload)
                    .then((res) => {
                        this.data = res.data.data;
                        this.form = res.data.data;
                        response.clearErrors();
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        removeAttachment(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios
                    .delete(this.apiRoute + "/" + this.data.id + "/attachments/" + payload)
                    .then((res) => {
                        response.clearErrors();
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        joinCompany(payload) {
            const response = useResponseStore();
            return new Promise((resolve, reject) => {
                $axios
                    .post(this.apiRoute + "/join", payload)
                    .then((res) => {
                        response.clearErrors();
                        resolve(res.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },

    getters: {
        countFilter() {
            if (this.filter != null)
                return Object.values(this.filter).filter((val) => val != null).length;
            return 0;
        },
        isCreate() {
            return this.create ? true : false;
        },
        isEdit() {
            return this.edit ? true : false;
        },
        isView() {
            return this.view ? true : false;
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(useModuleCompanyStore, import.meta.hot)
    );
}
