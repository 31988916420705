import { createRouter, createWebHistory } from 'vue-router';
import generatedRoutes from '~pages';

const Router = createRouter({
    history: createWebHistory(),
    routes: generatedRoutes,  // config routes
    //linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({
                    left: 0,
                    top: 0,
                    behavior: 'smooth'
                })
            }, 100)
        })
    },
});

Router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed")) {
        setTimeout(() => {
            window.location.reload(true);
        }, 2000);
    }
})

export default Router;
